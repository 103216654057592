// 现有安消防系统硬件设施信息
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'XFXTMC',
                label: '安消防系统名称',
                minWidth: 135,
            }, {
                prop: 'YTFL',
                label: '用途分类'
            }, {
                prop: 'LJ',
                label: '附件',
                showFileBtn: true,
            }],
        },
    },
};
export default selfData;